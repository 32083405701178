@import url(https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300;700&display=swap);
:root {
  --sidebar-width: 10rem;
  --main-header-size: 10rem;
  --footer-height: 3rem;
  --logo-scale: 1;
  --color-main: #333;
  --color-bg: #ddd;
  --color-emphasis: #377;
  --hoverable-button-size: 4rem;
}

body {
  color: #333;
  color: var(--color-main);
  background-color: #ddd;
  background-color: var(--color-bg);
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Advent Pro', sans-serif;
}

#tutorial {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #ddd;
  background-color: var(--color-bg);
  transition: all 0.3s;
}

#tutorial > h1 {
  position: absolute;
  font-size: calc(10rem / 2);
  font-size: calc(var(--main-header-size) / 2);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 1rem;
}

#tutorial button > * {
  font-size: 1.6rem;
  pointer-events: none;
}

.flex-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.flex-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.app {
  position: relative;
  /* height: auto; */
  width: 100%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  overflow-x: hidden;
}

main {
  position: relative;
  height: 100vh;
  width: calc(100vw  - 10rem);
  width: calc(100vw  - var(--sidebar-width));
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

main.small, main.extra-small {
  width: 100vw;
}

main > section {
  position: absolute;
  top: 0;
  height: calc(100vh - 3rem);
  height: calc(100vh - var(--footer-height));
  width: 100%;
}

#sidebar {
  position: fixed;
  -webkit-justify-content: center;
          justify-content: center;
  top: 0;
  left: 0;
  background-color: #ddd;
  background-color: var(--color-bg);
  height: 10rem;
  height: var(--sidebar-width);
  width: 10rem;
  width: var(--sidebar-width);
  z-index: 1;
}

#sidebar > nav {
  height: 50%;
  width: 100%;
}

#sidebar > .hoverable-button {
  position: absolute;
  top: 10rem;
  top: var(--sidebar-width);
  left: 10rem;
  left: var(--sidebar-width);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#sidebar.active {
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 0;
  border-right: 0.2rem solid #333;
  border-right: 0.2rem solid var(--color-main);
  height: 100vh;
}

#sidebar.active::after {
  content: '';
  position: absolute;
  left: 100%;
  bottom: 3rem;
  bottom: var(--footer-height);
  height: 0.4rem;
  width: 0.4rem;
  background-color: #377;
  background-color: var(--color-emphasis);
  box-shadow: 0 0 0 0.2rem #ddd, 0 0 0 0.3rem #333;
  box-shadow: 0 0 0 0.2rem var(--color-bg), 0 0 0 0.3rem var(--color-main);
  -webkit-transform: translate(calc(-50% + 0.1rem), 0.1rem) rotate(45deg);
          transform: translate(calc(-50% + 0.1rem), 0.1rem) rotate(45deg);
}

#sidebar > nav > ul {
  list-style: none;
  height: 100%;
}

#sidebar > nav > ul a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 4rem;
  height: var(--hoverable-button-size);
  text-decoration: none;
  color: #333;
  color: var(--color-main);
  font-size: 2rem;
}

#sidebar > nav > ul li {
  position: relative;
  width: 100%;
  transition: all 0.2s;
} 

#sidebar > nav > ul li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.2rem;
  width: 0%;
  margin-left: 50%;
  background-color: #377;
  background-color: var(--color-emphasis);
  transition: all 0.2s;
} 

#sidebar > nav > ul li:hover {
  width: 100%;
} 

#sidebar > nav > ul li:hover::after {
  width: 100%;
  margin-left: 0%;
} 

#sidebar.medium a {
  font-size: 1.6rem;
}

.logo {
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.logo::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1.6rem;
  width: 1.6rem;
  background-color: #666;
  border-top-right-radius: calc(1.6rem / 3);
  border-bottom-left-radius: calc(1.6rem / 3);
  box-shadow: 
    0.2rem -0.2rem 0 0.1rem #488,
    0.4rem -0.4rem 0 0.2rem #366,
    0.6rem -0.6rem 0 0.3rem #333;
  -webkit-transform: translate(-75%, -50%) rotate(45deg) scale(1);
          transform: translate(-75%, -50%) rotate(45deg) scale(1);
  -webkit-transform: translate(-75%, -50%) rotate(45deg) scale(var(--logo-scale));
          transform: translate(-75%, -50%) rotate(45deg) scale(var(--logo-scale));
  transition: box-shadow 0.3s, border-radius 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, border-radius 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, border-radius 0.3s, -webkit-transform 0.3s;
}

.logo.active::after {
  box-shadow: 
  0.3rem -0.3rem 0 0.1rem #488,
  0.7rem -0.7rem 0 0.2rem #366,
  1rem -1rem 0 0.3rem #333;
  -webkit-transform: translate(-100%, -50%) rotate(45deg) scale(1);
          transform: translate(-100%, -50%) rotate(45deg) scale(1);
  -webkit-transform: translate(-100%, -50%) rotate(45deg) scale(var(--logo-scale));
          transform: translate(-100%, -50%) rotate(45deg) scale(var(--logo-scale));
  border-top-right-radius: 0rem;
  border-bottom-left-radius: 1rem;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  height: var(--footer-height);
  width: 100%;
  border-top: 0.1rem solid #333;
  border-top: 0.1rem solid var(--color-main);
}

#home {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

#home-title {
  font-size: 10rem;
  font-size: var(--main-header-size);
  width: 10ch;
  text-align: center;
  margin-top: 3.2rem;
  overflow-y: hidden;
  transition: all 0.2s;
}

#home-title.large > span > span:first-child {
  display: inline-block ;
  -webkit-transform: translate(calc(10rem / 2 * (-1)), 0);
          transform: translate(calc(10rem / 2 * (-1)), 0);
  -webkit-transform: translate(calc(var(--main-header-size) / 2 * (-1)), 0);
          transform: translate(calc(var(--main-header-size) / 2 * (-1)), 0);
}

#home-title.large > span > span:last-child {
  display: inline-block ;
  -webkit-transform: translate(calc(10rem / 2), 0);
          transform: translate(calc(10rem / 2), 0);
  -webkit-transform: translate(calc(var(--main-header-size) / 2), 0);
          transform: translate(calc(var(--main-header-size) / 2), 0);
}

#home-title > * {
  display: inline-block;
}

#home > p {
  text-align: center;
  margin-top: 5rem;
  font-size: 2.6rem;
  width: 80%;
  max-width: 40ch;
}

#home > p.default-display {
  font-size: calc(10rem / 2.5);
  font-size: calc(var(--main-header-size) / 2.5);
}

#home > p.small, #home > p.extra-small {
  margin-top: 1rem;
  font-size: 2rem;
}

#home-buttons {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  list-style: none;
  width: 100%;
  margin-top: 3.2rem;
  max-width: 1000px;
}

#home .hoverable-button {
  font-size: 1.6rem;
}

#home.large .hoverable-button {
  font-size: 2rem;
}

#projects {
  --projects: 2;
}

#projects > h2 {
  font-size: calc(10rem / 1.8);
  font-size: calc(var(--main-header-size) / 1.8);
  overflow-y: hidden;
  line-height: 10rem;
  padding-bottom: 0.2ch;
  margin-bottom: 0rem;
}

.project-container {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  margin: 1rem 0;
  height: calc((100vh - 3rem) / var(--projects));
  height: calc((100vh - var(--footer-height)) / var(--projects));
}

.project-container.large {
  /* width: 66%; */
  min-width: 40rem;
  max-width: 50rem;
}

.project-container.reverse, .project-info.reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.project-container > * {
  width: 100%;
  text-align: center;
}

.project-title {
  position: relative;
  height: 100%;
}

.project-title.normal {
  border-bottom-right-radius: 50%;
  margin-right: 1rem;
  box-shadow: 0.2rem 0.2rem 0 0.05rem #333;
  box-shadow: 0.2rem 0.2rem 0 0.05rem var(--color-main);
}

.project-title.normal::after {
  content: '';
  position: absolute;
  color: #333;
  color: var(--color-main);
  bottom: 0;
  left: 0%;
  width: 0.6rem;
  height: 0.6rem;
  box-shadow: 
    0 0 0 0.15rem #377,
    0 0 0 0.3rem #ddd,
    0.8rem 0.8rem 0 0.2rem currentColor,
    -0.8rem -0.8rem 0 0rem currentColor;
  box-shadow: 
    0 0 0 0.15rem var(--color-emphasis),
    0 0 0 0.3rem var(--color-bg),
    0.8rem 0.8rem 0 0.2rem currentColor,
    -0.8rem -0.8rem 0 0rem currentColor;
  background-color: #ddd;
  background-color: var(--color-bg);
  -webkit-transform: translate(0, calc(50% + 0.1rem)) rotate(-45deg);
          transform: translate(0, calc(50% + 0.1rem)) rotate(-45deg);
}

.project-title.normal::before {
  content: '';
  position: absolute;
  color: #333;
  color: var(--color-main);
  top: 50%;
  right: 0;
  font-size: 2rem;
  width: 1rem;
  height: 1rem;
  box-shadow: 0 0 0 1rem #ddd;
  box-shadow: 0 0 0 1rem var(--color-bg);
  border-radius: 0.2rem;
  background: linear-gradient(45deg, #377, #333);
  background: linear-gradient(45deg, var(--color-emphasis), var(--color-main));
  -webkit-transform: translate(calc(50% + 0.2rem), -50%) rotate(45deg);
          transform: translate(calc(50% + 0.2rem), -50%) rotate(45deg);
}

.project-title.reverse {
  border-top-left-radius: 50%;
  margin-left: 1rem;
  box-shadow: -0.2rem -0.2rem 0 0.05rem #333;
  box-shadow: -0.2rem -0.2rem 0 0.05rem var(--color-main);
}

.project-title.reverse::after {
  content: '';
  position: absolute;
  color: #333;
  color: var(--color-main);
  top: 0;
  right: 0;
  width: 0.6rem;
  height: 0.6rem;
  box-shadow: 
    0 0 0 0.15rem #377,
    0 0 0 0.3rem #ddd,
    0.8rem 0.8rem 0 0.2rem currentColor,
    -0.8rem -0.8rem 0 0rem currentColor;
  box-shadow: 
    0 0 0 0.15rem var(--color-emphasis),
    0 0 0 0.3rem var(--color-bg),
    0.8rem 0.8rem 0 0.2rem currentColor,
    -0.8rem -0.8rem 0 0rem currentColor;
  background-color: #ddd;
  background-color: var(--color-bg);
  -webkit-transform: translate(0, calc(-50% - 0.1rem)) rotate(135deg);
          transform: translate(0, calc(-50% - 0.1rem)) rotate(135deg);
}

.project-title.reverse::before {
  content: '';
  position: absolute;
  color: #333;
  color: var(--color-main);
  top: 50%;
  left: 0;
  font-size: 2rem;
  width: 1rem;
  height: 1rem;
  box-shadow: 0 0 0 1rem #ddd;
  box-shadow: 0 0 0 1rem var(--color-bg);
  border-radius: 0.2rem;
  background: linear-gradient(45deg, #377, #333);
  background: linear-gradient(45deg, var(--color-emphasis), var(--color-main));
  -webkit-transform: translate(calc(-50% - 0.2rem), -50%) rotate(-135deg);
          transform: translate(calc(-50% - 0.2rem), -50%) rotate(-135deg);
}

.project-title > a {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  font-size: calc(10rem / 2.7);
  font-size: calc(var(--main-header-size) / 2.7);
  color: #377;
  color: var(--color-emphasis);
  text-decoration: none;
  transition: all 0.2s;
}

.project-title > a::after {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 50%;
  opacity: 0;
  height: 0.4ch;
  width: 0.4ch;
  border-top-right-radius: 0.1ch;
  border-bottom-left-radius: 0.1ch;
  background-color: #377;
  background-color: var(--color-emphasis);
  box-shadow: 
  0.02ch 0.02ch 0 #577,
  0.06ch 0.06ch 0 #488;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  transition: all 0.2s;
}

.project-title > a::before {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 50%;
  height: 0.1ch;
  width: 0;
  border-radius: 5px;
  background-color: #377;
  background-color: var(--color-emphasis);
  overflow: hidden;
  -webkit-transform: translate(-50%, 1.5ch);
          transform: translate(-50%, 1.5ch);
  transition: all 0.2s;
}

.project-title > a:hover {
  font-size: calc(10rem / 2.5);
  font-size: calc(var(--main-header-size) / 2.5);
}

.project-title > a:hover::after {
  opacity: 1;
  -webkit-transform: translate(-50%, -1ch) rotate(45deg);
          transform: translate(-50%, -1ch) rotate(45deg);
}

.project-title > a:hover::before {
  width: 33%;
}

.project-info {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  height: 100%;
}

.project-info.small, .project-info.extra-small {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.project-info > p {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-size: calc(10rem / 4);
  font-size: calc(var(--main-header-size) / 4);
  width: 60%;
  height: 66%;
  margin: 0 1rem;
  overflow-y: hidden;
}

.project-info > p > span.emphasised-text {
  font-size: calc(10rem / 4.5);
  font-size: calc(var(--main-header-size) / 4.5);
  margin: 1rem 0.2rem;
  overflow-y: hidden;
}

.project-info > p.large {
  font-size: calc(10rem / 6);
  font-size: calc(var(--main-header-size) / 6);
}

.project-info > p.medium {
  font-size: calc(10rem / 6.5);
  font-size: calc(var(--main-header-size) / 6.5);
}

.project-info > p.small {
  width: 90%;
}

.project-info > p.extra-small {
  width: 90%;
}

#contact {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

#contact > h2 {
  font-size: calc(10rem / 1.5);
  font-size: calc(var(--main-header-size) / 1.5);
  text-align: center;
  overflow-y: hidden;
}

#contact-options {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  max-height: 75%;
  max-width: 1000px;
}

#contact-options.small, #contact-options.extra-small {
  height: 100%;
}

#contact address {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

#contact address > .hoverable-button:nth-child(2n) {
  margin: calc(4rem / 2);
  margin: calc(var(--hoverable-button-size) / 2);
}

#contact form {
  position: relative;
}

#contact fieldset {
  border: 0.2rem solid #333;
  border: 0.2rem solid var(--color-main);
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #ddd;
  background-color: var(--color-bg);
}

#contact fieldset > legend {
  font-size: calc(4rem / 3);
  font-size: calc(var(--hoverable-button-size) / 3);
  margin-left: 1rem;
  padding: 0 1rem;
  overflow-y: hidden;
}

#contact fieldset ~ button {
  position: absolute; 
  top: calc(100% - 4rem / 1.4); 
  top: calc(100% - var(--hoverable-button-size) / 1.4);
  left: calc(100% - 4rem / 1.4);
  left: calc(100% - var(--hoverable-button-size) / 1.4);
  transition: all 0.25s;
}

#mailInput {
  height: calc(4rem * 3 + 4rem);
  height: calc(var(--hoverable-button-size) * 3 + 4rem);
  width: 100%;
  box-shadow: 0 0 0 0.1rem #333;
  box-shadow: 0 0 0 0.1rem var(--color-main);
  outline: none;
  border: none;
  box-sizing: border-box;
  border-radius: 0.4rem;
  font-size: 1.8rem;
  padding: 0.6rem;
  background-color: #ddd;
  background-color: var(--color-bg);
  resize: none;
}

#mailInput.small, #mailInput.extra-small {
  font-size: 1rem;
  height: calc(4rem * 3);
  height: calc(var(--hoverable-button-size) * 3);
}

#mailInput.large {
  font-size: 2rem;
  height: calc(4rem * 3 + 4rem);
  height: calc(var(--hoverable-button-size) * 3 + 4rem);
  width: calc(4rem * 5);
  width: calc(var(--hoverable-button-size) * 5);
}

#emailInput {
  width: 100%;
  margin-bottom: 2rem;
  box-shadow: 0 0 0 0.1rem #333;
  box-shadow: 0 0 0 0.1rem var(--color-main);
  outline: none;
  border: none;
  box-sizing: border-box;
  border-radius: 0.4rem;
  font-size: 1.8rem;
  padding: 0.6rem;
  background-color: #ddd;
  background-color: var(--color-bg);
}

#emailInput.small, #emailInput.extra-small {
  font-size: 1rem;
  margin-bottom: 0.6rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  z-index: 99;
}

.card {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem;
  top: 50%;
  left: 50%;
  min-height: 20rem;
  width: 33%;
  min-width: 400px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ddd;
  background-color: var(--color-bg);
  border-radius: 1em;
  z-index: 1;
}

.card > h2 {
  text-align: center;
  font-size: 3rem;
}

.card > p {
  /* text-align: center; */
  padding: .5em 1.25em;
  font-size: 1.6rem;
}

.modal-close {
  position: absolute;
  top: .5rem;
  left: .5rem;
  height: 2rem;
  width: 2rem;
  background-color: transparent;
  border-radius: 50%;
  border: none;
  outline: none;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 2;
}

.modal-close::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 5px;
  width: 100%;
  background-color: #333;
  background-color: var(--color-main);
}

.modal-close::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100%;
  width: 5px;
  background-color: #333;
  background-color: var(--color-main);
  overflow: hidden;
}

.modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #333;
  background-color: var(--color-main);
  -webkit-filter: opacity(0.8);
          filter: opacity(0.8);
}

.hoverable-button {
  position: relative;
  height: 4rem;
  height: var(--hoverable-button-size);
  width: 4rem;
  width: var(--hoverable-button-size);
  font-size: calc(4rem / 3.5);
  font-size: calc(var(--hoverable-button-size) / 3.5);
  border: none;
  outline: none;
  background-color: #ddd;
  background-color: var(--color-bg);
  border-radius: 50%;
  box-shadow: 0 0 0 0.2rem #333;
  box-shadow: 0 0 0 0.2rem var(--color-main);
  cursor: pointer;
  transition: all 0.6s, text-shadow 0.3s;
  -webkit-animation: buttonExpand 0.4s;
          animation: buttonExpand 0.4s;
  z-index: 13;
}

.hoverable-button.small {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.hoverable-button.large {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.hoverable-link {
  display: -webkit-flex;
  display: flex;
  font-size: calc(4rem / 3.5);
  font-size: calc(var(--hoverable-button-size) / 3.5);
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #333;
  color: var(--color-main);
  text-decoration: none;
  margin-top: auto;
  width: 4rem;
  width: var(--hoverable-button-size);
  height: 4rem;
  height: var(--hoverable-button-size);
  transition: text-shadow 0.3s;
}

.hoverable-button.active {
  box-shadow: 
    0 0 0.2rem 0.3rem #333,
    0 0 0rem 0.4rem #ddd,
    0 0 0rem 0.6rem #377;
  box-shadow: 
    0 0 0.2rem 0.3rem var(--color-main),
    0 0 0rem 0.4rem var(--color-bg),
    0 0 0rem 0.6rem var(--color-emphasis);
}

.hoverable-button-decoration {
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  pointer-events: none;
}

.hoverable-button-decoration::after {
  content: '';
  position: absolute;
  bottom: 75%;
  left: 75%;
  /* Show's after fade in animation */
  opacity: 0;
  background-color: #333;
  background-color: var(--color-main);
  height: calc(4rem / 6);
  height: calc(var(--hoverable-button-size) / 6);
  width: calc(4rem / 6);
  width: calc(var(--hoverable-button-size) / 6);
  border-top-left-radius: 25%;
  border-bottom-right-radius: 25%;
  -webkit-animation: decorationFade 0.3s 0.5s forwards;
          animation: decorationFade 0.3s 0.5s forwards;
  -webkit-transform: translate(0.1rem, -0.1rem);
          transform: translate(0.1rem, -0.1rem);
  box-shadow: 
  0rem 0rem 0 0rem #555,
  0rem 0rem 0 0rem #444,
  0rem 0rem 0 0rem #333,
  0rem 0rem 0 0.2rem #ddd;
  box-shadow: 
  0rem 0rem 0 0rem #555,
  0rem 0rem 0 0rem #444,
  0rem 0rem 0 0rem #333,
  0rem 0rem 0 0.2rem var(--color-bg);
  transition: bottom 0.4s, left 0.4s, box-shadow 0.4s, background-color 0.4s;
}

.hoverable-button-decoration::before {
  content: '';
  position: absolute;
  top: 75%;
  right: 75%;
  /* Show's after fade in animation */
  opacity: 0;
  background-color: #333;
  background-color: var(--color-main);
  height: calc(4rem / 6);
  height: calc(var(--hoverable-button-size) / 6);
  width: calc(4rem / 6);
  width: calc(var(--hoverable-button-size) / 6);
  border-top-left-radius: 25%;
  border-bottom-right-radius: 25%;
  -webkit-animation: decorationFade 0.3s 0.5s forwards;
          animation: decorationFade 0.3s 0.5s forwards;
  -webkit-transform: translate(-0.1rem, 0.1rem);
          transform: translate(-0.1rem, 0.1rem);
  box-shadow: 
    0rem 0rem 0 0rem #555,
    0rem 0rem 0 0rem #444,
    0rem 0rem 0 0rem #333,
    0rem 0rem 0 0.2rem #ddd;
  box-shadow: 
    0rem 0rem 0 0rem #555,
    0rem 0rem 0 0rem #444,
    0rem 0rem 0 0rem #333,
    0rem 0rem 0 0.2rem var(--color-bg);
  transition: top 0.4s, right 0.4s, box-shadow 0.4s, background-color 0.4s;
}

.hoverable-button-decoration.active::after {
  bottom: 100%;
  left: 100%;
  background-color: #666;
  box-shadow: 
    -0.2rem 0.2rem 0 0rem #488,
    -0.4rem 0.4rem 0 0.1rem #366,
    -0.6rem 0.6rem 0 0.2rem #333,
    -0.8rem 0.8rem 0 0.2rem #ddd;
  box-shadow: 
    -0.2rem 0.2rem 0 0rem #488,
    -0.4rem 0.4rem 0 0.1rem #366,
    -0.6rem 0.6rem 0 0.2rem #333,
    -0.8rem 0.8rem 0 0.2rem var(--color-bg);
}

.hoverable-button-decoration.active::before {
  top: 100%;
  right: 100%;
  background-color: #666;
  box-shadow: 
    0.2rem -0.2rem 0 0rem #488,
    0.4rem -0.4rem 0 0.1rem #366,
    0.6rem -0.6rem 0 0.2rem #333,
    0.8rem -0.8rem 0 0.2rem #ddd;
  box-shadow: 
    0.2rem -0.2rem 0 0rem #488,
    0.4rem -0.4rem 0 0.1rem #366,
    0.6rem -0.6rem 0 0.2rem #333,
    0.8rem -0.8rem 0 0.2rem var(--color-bg);
}

#cursor {
  position: absolute;
  top: -100%;
  left: -100%;
  border-radius: 50%;
  background-color: transparent;
  height: calc(4rem / 2);
  height: calc(var(--hoverable-button-size) / 2);
  width: calc(4rem / 2);
  width: calc(var(--hoverable-button-size) / 2);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0 0 0rem 0.2rem #fff;
  opacity: 0.4;
  /* mix-blend-mode: difference; */
  z-index: 10;
  pointer-events: none;
  transition: box-shadow 0.4s 0.2s, background-color 0.2s;
}

#cursor.focused {
  box-shadow: 0 0 0rem 0.2rem #277;
  -webkit-transform: translate(0 , 0) scale(1.4);
          transform: translate(0 , 0) scale(1.4);
}

/* ANIMATIONS */

.rotate {
  -webkit-animation: rotation 20s infinite linear;
          animation: rotation 20s infinite linear;
}

@-webkit-keyframes rotation {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes buttonExpand {
  from {
    height: 0;
    width: 0;
    overflow: hidden;
    pointer-events: none;
  }
  30% { 
    overflow: hidden;
  }
  60% { 
    overflow: hidden;
  }
  80% {
    overflow: hidden;
    pointer-events: none;
  }
}

@keyframes buttonExpand {
  from {
    height: 0;
    width: 0;
    overflow: hidden;
    pointer-events: none;
  }
  30% { 
    overflow: hidden;
  }
  60% { 
    overflow: hidden;
  }
  80% {
    overflow: hidden;
    pointer-events: none;
  }
}

@-webkit-keyframes decorationFade {
  from {
    width: 0;
    height: 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  to {
    opacity: 1;
  }
}

@keyframes decorationFade {
  from {
    width: 0;
    height: 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  to {
    opacity: 1;
  }
}

/* TEXT-ANIMATIONS */

.emphasised-text {
  position: relative;
  display: inline-block;
  color: #377;
  color: var(--color-emphasis);
  transition: all 0.2s;
}

.animated-text.characters {
  opacity: 0;
  -webkit-animation: characters 0.2s forwards;
          animation: characters 0.2s forwards;
  transition: text-shadow 0.2s;
}

@-webkit-keyframes characters {
  to {
    opacity: 1;
  }
}

@keyframes characters {
  to {
    opacity: 1;
  }
}

.animated-text.words {
  display: inline-block;
  -webkit-transform: translate(0%, 100%);
          transform: translate(0%, 100%);
  -webkit-animation: words 1s 2s forwards;
          animation: words 1s 2s forwards;
}

@-webkit-keyframes words {
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes words {
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
